import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${
      queryFilter.sort
    },${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/usuarios${queryUrl}`)
  },
  insertNewUsers(data) {
    return axios.post('/usuarios/', data)
  },
  editUsers(data) {
    return axios.put(`/usuarios/${data.id}`, data)
  },
  deleteUsers(id) {
    return axios.put(`/usuarios/${id}`)
  },
  loadPermission() {
    return axios.get('/core/roles?page=0&size=100&sort=authority')
  },
  getById(id) {
    return axios.get(`/usuarios/${id}`)
  }
}
